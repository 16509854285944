<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6">
        <site-group-filter store="admin" />
      </v-col>
      <v-col cols="6">
        <site-filter store="admin" />
      </v-col>

      <v-col cols="3">
        <date-filter
          store="admin"
          format="month"
          :isRange="false"
          periodType="past-only"
          storeGetter="getMonthDate"
          storeUpdater="updateMonthDate"
        ></date-filter>
      </v-col>

      <v-col cols="9" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    SiteGroupFilter,
    SiteFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
